import { VBaseService } from '@libTs/vue-base';

export type TOverlayHandle = 'none' | string;

export interface IOverlay {
  handle: TOverlayHandle;
  obj?: any;
}

export class OverlaysService extends VBaseService {
  private _handles: Array<string> = [
    'none'
  ];

  private readonly overlay: IOverlay = {
    handle: 'none',
    obj: undefined
  };

  public get obj(): any {
    if ( this.has() ) {
      return this.overlay.obj;
    }
    return undefined;
  }

  public has( handle?: TOverlayHandle ): boolean {
    return this.overlay.handle !== 'none' && (
      0 <= this._handles.indexOf( this.overlay.handle )
      && ( !handle || this.overlay.handle === handle )
    );
  }

  public show( handle: TOverlayHandle, obj?: any ): void {
    console.log( 'show()', handle );

    if ( typeof handle === 'string' && 0 <= this._handles.indexOf( handle ) ) {
      this.overlay.handle = handle;
      this.overlay.obj = obj;
      console.log( 'Overlay set to:', this.overlay );

      this.updateDocBody();
    } else {
      console.warn( 'Overlay not found', handle );
      this.hide();
    }
  }

  public hide(): void {
    console.log( 'hide()' );

    this.overlay.handle = 'none';
    this.overlay.obj = undefined;

    this.updateDocBody();
  }

  public toggle( handle: TOverlayHandle, obj?: any ): void {
    if ( this.has( handle ) ) {
      this.hide();
    } else {
      this.show( handle, obj );
    }
  }

  public register( handle: string ): string {
    if ( typeof handle !== 'string' || handle.length === 0 || handle === 'none' ) {
      throw new Error( 'Overlays service: Empty handle given' );
    } else if ( 0 <= this._handles.indexOf( handle ) ) {
      throw new Error( `Overlays service: Handle "${handle}" already registered` );
    }
    this._handles.push( handle );
    console.log( `Overlay handle "${handle}" registered` );
    return handle;
  }

  private updateDocBody(): void {
    // set body overflow
    if ( 0 <= this._handles.indexOf( this.overlay.handle ) ) {
      if ( this.overlay.handle === 'none' ) {
        document.body.style.overflowY = 'auto';
        document.body.classList.remove( 'has-overlay' );
      } else {
        if ( !document.body.classList.contains( 'has-overlay' ) ) {
          document.body.classList.add( 'has-overlay' );
        }
        document.body.style.overflowY = 'hidden';
      }
    } else {
      document.body.classList.remove( 'has-overlay' );
      document.body.style.overflowY = 'auto';
      console.warn( 'invalid overlay handle "' + this.overlay.handle + '"' );
    }
  }
}