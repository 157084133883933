/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 *
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { createVueApp } from '@libTs/vue-base';
import { BASEURL, SITEURL } from './cms.globals';

import { OverlaysService } from './services/overlays.service';
import { VideoService } from './services/video.service';

import { OverlayDirective } from './directives/v-overlay.directive';
import { ResetDirective } from './directives/v-reset.directive';

// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app

createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#app',
    services: [
      OverlaysService,
      VideoService
    ],
    directives: [
      OverlayDirective,
      ResetDirective
    ],
    components: {
    },
    modules: []
  }
);
