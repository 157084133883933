import { VDirective } from '@libTs/vue-base/v.directive';
import { DirectiveBinding } from 'vue/types/options';
import { SITEURL } from '../cms.globals';

export const ResetDirective: VDirective = [
  'reset',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      let interval: NodeJS.Timeout | undefined = undefined;
      let secondsSinceLastActivity = 0;
      const maxInactivity = 60 * 5; //In sec.

      function timer() {
        secondsSinceLastActivity++;
        if ( secondsSinceLastActivity === maxInactivity ) {
          window.location.href = SITEURL;
        }
      }

      interval = setInterval( timer, 1000 );

      $( 'html' ).on( 'click', () => {
        clearInterval( interval as NodeJS.Timeout );
        secondsSinceLastActivity = 0;
        interval = setInterval( timer, 1000 );
      } );
    },
  },
];
