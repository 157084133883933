import { VBaseService } from '@libTs/vue-base';

export class VideoService extends VBaseService {
  public interval: NodeJS.Timeout | undefined = undefined;
  public isPlaying: boolean = false;
  public isMuted: boolean = false;
  public play( videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    video.play();
    this.isPlaying = true;
    this.interval = setInterval( () => {
      this.calcTimeLeft( videoId );
    }, 1 );
  }

  public pause( videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    video.pause();
    this.isPlaying = false;
    clearInterval( this.interval as NodeJS.Timeout );
  }

  public calcTimeLeft( videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    const videoDuration = video.duration;
    const percentageDone = video.currentTime / videoDuration * 100;
    const status = $( video ).next().find( '.status-bar' );
    status.css( 'width', `${percentageDone}%` );
    if ( video.ended ) {
      this.reset( videoId );
    }
  }

  public skip( e: MouseEvent, videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    const target = e.target as HTMLElement;
    const position = e.clientX - target.getBoundingClientRect().left;
    const percentage = position / target.clientWidth;
    const skipTo = video.duration * percentage;

    video.currentTime = skipTo;
    this.calcTimeLeft( videoId );
  }

  public reset( videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    video.currentTime = 0;
    this.pause( videoId );
  }

  public toggleVolume( videoId: string ): void {
    const video = document.getElementById( videoId ) as HTMLVideoElement;
    if ( video.volume ) {
      video.volume = 0;
      this.isMuted = true;
    } else {
      video.volume = 1;
      this.isMuted = false;
    }
  }

  public togglePlay( videoId: string ): void {
    if ( this.isPlaying ) {
      this.pause( videoId );
    } else {
      this.play( videoId );
    }
  };
}